Vue.prototype.trans = function(key) {
    return _.get(window.trans, key, key);
};

$(function() {
	$('.wysiwyg').summernote({toolbar: [
		['style', ['bold', 'italic', 'underline', 'clear']],
		['fontsize', ['fontsize']],
		['para', ['ul', 'ol']]
	]});
	$('.matchable').matchHeight();
	$('.delete_button').click(function(e) {
		e.preventDefault();
		var href = $(this).attr('href');
		var token = $('input[name=_token]').val();
		var cancelNotes = $('textarea[name=cancel_notes]').val();
		sweetAlert({
			title: "Are you sure?",
			text: $(this).data('alertText'),
			type: "warning",
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Yes, delete",
			cancelButtonText: "No, cancel",
			closeOnConfirm: true,
			closeOnCancel: false }, function(isConfirmed) {
				if(isConfirmed) {
					$.ajax({
					    url: href,
					    type: 'DELETE',
					    data: {
					    	'_token' : token,
					    	'cancel_notes' : cancelNotes
					    },
					    success: function(redirect) {
					        window.location.replace(redirect);
					    }
					});
				} else {
					sweetAlert("Cancelled", "Deletion cancelled", "info");
				}
			}
		);
	});
	$('.clickable').click(function(e) {
		e.preventDefault();
		window.document.location = $(this).data("href");
	});

	$('form').on('change', 'input.upload_control', function() {
		var col = $(this).data('col');
		col = (col) ? col : 6;
	    readURL(this, $('.panel-body', $(this).closest('.panel')), col);
	});

	function readURL(input, previewElement, col) {
		$('.preview.fresh', previewElement).remove();
	    if(window.FileReader) {
	        if (input.files && input.files[0]) {
	            for (f = 0; f < input.files.length; ++f) {
	                var file = input.files[f];
	                if (file.type.match('image/jpeg') || file.type.match('image/png') || file.type.match('image/gif') || file.type.match('image/bmp')) {
	                    var reader = new FileReader();
	                    reader.onload = function (e) {
	                        previewElement.prepend('<div class="preview fresh col-sm-'+col+'"><div class="thumbnail"><img src="'+e.target.result+'"/></div></div>');
	                    }
	                    reader.readAsDataURL(file);
	                } else {
	                    var iconableExtensions = ['tiff','eps','pdf','psd','ai', 'xls', 'xlsx', 'doc', 'docx'];
	                    var extension = file.name.split('.').pop().toLowerCase();
	                    if (iconableExtensions.indexOf(extension)>-1) {
	                        previewElement.prepend('<div class="preview fresh col-sm-'+col+'"><div class="thumbnail"><img src="/images/icons/icon-'+extension+'.png" /></div></div>');
	                    } else {
	                    	if (allowableExtensions.indexOf(extension)>-1) {
	                    		previewElement.prepend('<div class="preview fresh col-sm-'+col+'"><div class="thumbnail"><img src="/images/icons/icon-generic.png" /><span class="extension">'+extension+'</span></div></div>');
	                    	} else {
	                    		previewElement.prepend('<div class="preview fresh col-sm-'+col+'"><div class="thumbnail"><img src="/images/icons/icon-invalid.png" /><span class="extension">'+extension+'</span></div></div>');
	                    	}
	                    }
	                }
	            }
	        }
	    }
	}

    $(function() {
    	$('form.validate').on('submit', function(e) {
    		e.preventDefault();
    		var method = $('input[name="_method"]').val();
    		if(!method) { method = $(this).prop('method'); }
 	    $.ajax({
          type: method,
          url: $(this).prop('action'),
          data: $(this).serialize(),
          dataType: "json",
          success: function(redirect) {
            window.location.assign(redirect.url);
          },
          error: function(data) {
          	var errors = data.responseJSON;
          	var eList = $('<ul/>').addClass('list-group');
          	$.each(errors, function(e, error)
          	{
          	    var li = $('<li/>')
          	    	.html(error)
          	    	.addClass('list-group-item')
          	    	.appendTo(eList)
          	    ;
          	});
            sweetAlert({
            	title: "Please check the following:",
            	text: eList.prop('outerHTML'),
            	type: "error",
            	html: true,
            	showConfirmButton: true,
            	closeOnConfirm: true,
            	confirmButtonText: 'OK',
            	// confirmButtonColor: '#EC971F',
            	allowEscapeKey: true,
            	allowOutsideClick: true,
            	showCancelButton: false,
            	timer: 10000
            });
          }
        });
	    });
   	});
});